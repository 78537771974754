/**
 * 业务逻辑util
 */
import {
  default as config,
  _publicKeyPEM,
  pageEnum,
  tabBarMenu,
  needLoginRouter,
  DEFAULT_PAGE_CHANEL,
  siteEnum,
} from '@config';
import { GAMEAPI, USERAPI, COMMONAPI } from '@api';
import JSEncrypt from 'jsencrypt';
// import packageConfig from '../../../package.json';
import packageConfig from '../../manifest.json';
import CryptoJS from 'crypto-js';
import { useCommonStore } from '@stores/common.js';
import { storeToRefs } from 'pinia';
import { $t } from './language';

// #ifdef H5
import Compressor from 'compressorjs';
// #endif

/**
 * 区分网站,
 * @param {*} arr 网站列表：['gana100','suerte777']
 */
export function checkSite(arr, flag = true) {
  if (flag) {
    arr.unshift('dev');
  }
  let res = false;
  for (let i = 0; i < arr.length; i++) {
    if (siteEnum[arr[i]]?.includes(config._hostName)) {
      res = true;
      break;
    }
  }
  return res;
}

/**
 * 区分平台
 * @returns
 */
export function checkPlatform() {
  let platform = 'h5';
  const client = checkBrowser();
  //#ifdef APP-PLUS
  platform = 'app'; // uni.getSystemInfoSync().platform; // ios android
  // #endif
  //#ifdef H5
  if (getQueryString('siteType') === 'w2a' || getLocalData('siteType') === 'w2a') {
    // 套壳app
    platform = 'w2a';
  } else if (window.Telegram?.WebApp?.initData) {
    platform = 'TMA';
  } else if (client.isDesktop) {
    platform = 'pc';
  } else {
    platform = 'h5';
  }
  // #endif
  // return 'TMA';
  return platform;
}

/**
 * 点击banner跳转逻辑
 * @param {*} type 0：普通；1：活动；2：游戏
 * @param {*} bannerArr
 * @param {*} index
 */
export function clickBanner(bannerArr, index = 0) {
  let { type, url, activityId, gameId, supplierCode } = bannerArr[index];

  let name = '';
  if (type === 0) {
    name = '默认';
  } else if (type === 1) {
    name = '活动';
  } else if (type === 2) {
    name = '游戏';
  }
  try {
    ganaSendEvent(
      `banner-点击${name}-type:${type}-activityId:${activityId}-gameId:${gameId}-supplierCode:${supplierCode}`,
      {
        type,
        url,
        activityId,
        gameId,
        supplierCode,
      }
    );
  } catch (error) {}

  switch (type) {
    case 0:
      if (url) {
        // 如果要直接跳，请带上/pages路径，例如：/pages/index/index
        if (url.startsWith('/')) {
          url = url.substring(1);
        }
        gotoPage({
          page: url,
        });
      }
      break;
    case 1:
      if (url) {
        if (url.startsWith('/')) {
          url = url.substring(1);
        }
        gotoPage({
          page: url,
          query: { id: activityId },
        });
      } else if (activityId) {
        gotoPage({
          page: `/pages/activityDetail?id=${activityId}`,
        });
        // gotoPage({
        //   page: 'activityDetail',
        //   query: { id: activityId },
        // });
      }
      break;
    case 2:
      if (gameId) {
        const store = useCommonStore();
        // 检测是否充值过, true:重过，false:未充值
        GAMEAPI.CheckUserIsRecharge({ gameId }).then((res) => {
          if (res.playGame) {
            gotoPage({
              page: 'playGame',
              query: { id: gameId, code: supplierCode },
            });
          } else {
            store.switchIsShowFirstRechargeDialog(!res.userRecharge);
          }
        });
      }
      break;
    default:
      break;
  }
}

/**
 * 跳转游戏url
 * @param {*} urlStr 游戏路径
 * @param {*} gameId
 * @param {*} mode
 * @param {*} backUrl 返回回来跳转的路径
 */
export function gotoGame(urlStr, gameId, mode, backUrl) {
  !mode ? (mode = 'play') : '';
  !backUrl ? (backUrl = 'https://mx.igming.com/') : '';
  const lang = getLocalData('lang') || 'en_US';
  showLoading();
  // if (checkPlatform() === 'TMA') {
  //   USERAPI.syncHookCoin().then((res) => {
  //   }).catch(err){
  //     console.log("err", err)
  //   }
  // }
  return GAMEAPI.GetGameReqInfo({ gameId, modes: mode.toUpperCase() }).then((res) => {
    hideLoading();
    let src = `${urlStr}?mode=${mode.toLowerCase()}&lang=${lang}&content=${res}&url=${backUrl}`;
    // location.href = src
    // 采用ifream方式加载
    // createIframe(src)
    // callback(src)
    return src;
  });
}
/**
 * 统一跳转游戏方法
 * @param {*} id
 * @param {*} code
 */
/* export function handleCommonGotoGame({ id, code, mode = 'play' }) {
  const store = useCommonStore();
  const { isLogin } = storeToRefs(store);

  if (!isLogin.value) {
    gotoLogin();
  } else {
    if (mode.toLowerCase() == 'play') {
      // 检测是否充值过, true:重过，false:未充值
      GAMEAPI.CheckUserIsRecharge().then((res) => {
        store.switchIsShowFirstRechargeDialog(!res);
        if (res) {
          gotoPage({
            page: 'playGame',
            query: {
              id,
              mode,
              code,
            },
          });
        }
      });
    } else {
      gotoPage({
        page: 'playGame',
        query: {
          id,
          mode,
          code,
        },
      });
    }
  }
} */

/**
 * 拼接url上的params
 * @param {*} query
 */
function joinParams(url, query) {
  if (!query) return '';
  url += '?';
  for (let key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      url += `${key}=${query[key]}&`;
    }
  }
  return url.slice(0, -1);
}

/**
 * 处理h5在二级页面刷新后返回时，tabbar不显示问题
 */
export function setLocalTabbar() {
  // const store = useCommonStore();
  // let categroyList = store.categoryData.categroyList
  // if (categroyList.length === 0) return
  // // - 如果后台没配置Bingo，则显示Promo，并可跳转到Promo页面
  // const hasBingo = categroyList.filter(({ path }) => path === '/pages/bingo')
  // if (hasBingo.length > 0) {
  //   uni.setTabBarItem({
  //     index: 1,
  //     visible: true,
  //   });
  //   uni.setTabBarItem({
  //     index: 2,
  //     visible: false,
  //   });
  // } else {
  //   uni.setTabBarItem({
  //     index: 1,
  //     visible: false,
  //   });
  //   uni.setTabBarItem({
  //     index: 2,
  //     visible: true,
  //   });
  // }
  // // - 如果后台没配置Live，则显示Contatos，并可跳转到Contatos页面
  // const hasCasinoVivo = categroyList.filter(({ path }) => path === '/pages/casinoVivo')
  // if (hasCasinoVivo.length > 0) {
  //   uni.setTabBarItem({
  //     index: 3,
  //     visible: true,
  //   });
  //   uni.setTabBarItem({
  //     index: 4,
  //     visible: false,
  //   });
  // } else {
  //   uni.setTabBarItem({
  //     index: 3,
  //     visible: false,
  //   });
  //   uni.setTabBarItem({
  //     index: 4,
  //     visible: true,
  //   });
  // }
}
/**
 * 处理chanel()
 * 365gana_10000_0_-1(渠道：365gana_10000， s:0, pixel:-1[代表0])
 * 365gana_10002_3_306579948500532(渠道：365gana_10000， s:3, pixel:306579948500532)
 */
// export function handleChannel(chanel) {
//   let chanelObj = {};
//   // #ifdef H5
//   chanelObj = {
//     CONFIG_CHANEL: '',
//     channel: chanel || DEFAULT_PAGE_CHANEL, // 首页底部下载app
//     s: '',
//     acc: '',
//     pixel: '',
//   };
//   // #endif
//   // #ifdef APP-PLUS
//   let appChannel = plus.runtime.channel.split('_');
//   chanelObj = {
//     CONFIG_CHANEL: appChannel[0],
//     channel: `${appChannel[0]}_${appChannel[1]}`,
//     acc: appChannel[1],
//     s: appChannel[2],
//     pixel: appChannel[3] == -1 ? '' : appChannel[3],
//   };
//   // #endif
//   return chanelObj;
// }

/**
 * 默认返回（兼容h5返回）
 */
export function goBack(delta) {
  const store = useCommonStore();
  let backDelta = delta || 1;
  //#ifdef APP-PLUS
  //#endif
  let needHomeBack = '';
  let canNavBack = getCurrentPages();
  if (canNavBack && canNavBack.length > 1) {
    console.log('+++goBack2', canNavBack);
    // 如果上一个页面是注册页，则跳转到首页（需要关闭其他页面，防止首页会被mounted两遍，watch也会注册两次）
    let needHomeBack = canNavBack[canNavBack.length - 2].route;
    if (needHomeBack == 'pages/register' || needHomeBack === 'pages/resetPwd') {
      uni.reLaunch({
        url: getDefaultHome(),
      });
    } else if (
      ['pages/recharge'].includes(canNavBack[canNavBack.length - 1].route) &&
      !getLocalData(`${store.baseUserInfo.userName}_clickRechageBtn`)
    ) {
      // 充值页面添加弹窗确认
      store.setIsShowRechargeDia(true, '/' + needHomeBack);
    } else {
      uni.navigateBack({
        delta: backDelta,
      });
    }
  }
  //#ifdef H5
  else {
    // 处理：从注册页进入登录页，并在登录页刷新后点击登录
    needHomeBack = history.state.back;
    if (needHomeBack == '/pages/register' || needHomeBack == '/pages/resetPwd') {
      console.log('history.state.back', history.state.back);
      uni.reLaunch({
        url: getDefaultHome(),
      });
    } else if (
      ['/pages/recharge'].includes(history.state.current) &&
      !getLocalData(`${store.baseUserInfo.userName}_clickRechageBtn`)
    ) {
      // 充值页面添加弹窗确认
      store.setIsShowRechargeDia(true, needHomeBack);
    } else {
      history.back(backDelta);
    }
  }
  //#endif
}

/**
 * 获取当前页面的名称，方便登录后跳转使用
 */
export function getCurPage() {
  let curPage = getCurrentPages();
  let pages = curPage[curPage.length - 1];
  curPage = pages && pages.route.slice(6, -4);
  let page = curPage.substring(0, 1).toLowerCase() + curPage.substring(1);
  return page == 'login' ? '' : page;
}

// export function gotoLogin() {
//   let curPage = getCurPage();
//   let obj = {
//     page: "login",
//   };
//   curPage ? (obj["query"] = { redirect: curPage }) : "";
//   gotoPage(obj);
// }
export function gotoLogin() {
  let curPage = getCurrentPages();
  let pages = curPage[curPage.length - 1];
  let $page = pages && pages.$page && pages.$page.fullPath;
  let obj = {
    page: 'login',
  };
  // 检测是未注册用户，跳注册
  if (!getLocalData('isLoginOrRegisterMark')) {
    obj.page = 'register';
  }
  let redirect = getUrlParams($page, 'redirect');
  if (curPage) {
    if (redirect) {
      obj['query'] = redirect;
    } else {
      obj['query'] = { redirect: $page };
    }
  }
  // curPage ? (obj['query'] = { redirect: $page }) : '';
  gotoPage(obj);
}

/**
 * 跳转页面
 * @param {*} page 页面名称
 */
export function gotoPage({
  page,
  query,
  events,
  redirectTo,
  reLaunch,
  isJoinPageQuery = false,
  animationType = 'pop-in',
}) {
  if (page.startsWith('http://') || page.startsWith('https://')) {
    // 跳转外部链接h5
    // #ifdef H5
    window.location.href = page;
    // #endif
    // #ifdef APP-PLUS
    plus.runtime.openURL(page); //不需要拼接
    // plus.runtime.openURL(`http://${jumpUrl}`)//需要拼接
    // #endif
    return false;
  }

  // 必传的参数fbParams，s（统计来源）默认:0, 邮件:1, app:2, facebook:3, 网盟:8
  let fbParams = {};
  getQueryString('s') && (fbParams.s = getQueryString('s'));
  getQueryString('c') && (fbParams.c = getQueryString('c'));
  getQueryString('g') && (fbParams.g = getQueryString('g'));
  getQueryString('ad') && (fbParams.ad = getQueryString('ad'));
  getQueryString('inviteUrl') && (fbParams.inviteUrl = getQueryString('inviteUrl')); // 分享邀请链接
  getQueryString('siteType') && (fbParams.siteType = getQueryString('siteType')); // w2a
  getQueryString('channel') && (fbParams.channel = getQueryString('channel')); // 分享邀请链接

  const acc = getQueryString('acc');
  const aac = getQueryString('aac');
  if (acc || aac) {
    fbParams.acc = acc || aac;
  }
  const pixel = getQueryString('pixel');
  const pix = getQueryString('pix');
  if (pixel || pix) {
    fbParams.pixel = pixel || pix;
  }

  getQueryString('bbg') && (fbParams.bbg = getQueryString('bbg'));
  getQueryString('ttclid') && (fbParams.ttclid = getQueryString('ttclid'));

  const store = useCommonStore();
  const { isLogin } = storeToRefs(store);

  let curPage = getCurrentPages();
  let pages = curPage[curPage.length - 1];
  let $page = pages && pages.$page && pages.$page.fullPath;
  if (page.indexOf('?') >= 0) {
    // 也可以直接传拼接好的url，但是不能含有'/'
    let sp = page.split('?');
    page = sp[0];
    query = {};
    let temp = sp[1].split('&');
    temp.map((item) => {
      let it = item.split('=');
      query[it[0]] = it[1] || '';
    });
  }

  let url = '';
  let isTabBar = false;
  let needLogin = false;

  if (page.startsWith('/pages')) {
    isTabBar = Object.values(tabBarMenu).includes(page); // tabbar
    needLogin = Object.values(needLoginRouter).includes(page); // 需要登录
    url = page;
  } else {
    isTabBar = Object.keys(tabBarMenu).includes(page); // tabbar
    needLogin = Object.keys(needLoginRouter).includes(page); // 不需要登录
    if (isTabBar) {
      url = tabBarMenu[page];
    } else {
      url = pageEnum[page];
    }
  }

  if (!url) {
    return false;
  }
  if (needLogin && !isLogin.value) {
    // TODO: 需要登录 && 未登录
    if ($page.includes('pages/login')) {
      return false;
    } else {
      // 检测是未注册用户，跳注册
      if (!getLocalData('isLoginOrRegisterMark')) {
        url = '/pages/register';
      } else {
        url = '/pages/login';
        curPage ? (query = { redirect: $page }) : '';
      }
    }
  }

  // query && (url = joinParams(url, query));
  url = joinParams(url, { ...query, ...fbParams });

  if (redirectTo) {
    uni.redirectTo({
      url,
      events,
      animationType: isTabBar ? 'none' : animationType,
    });
  } else if (reLaunch) {
    uni.reLaunch({
      url,
      events,
      animationType: isTabBar ? 'none' : animationType,
    });
  } else {
    uni.navigateTo({
      url,
      events,
      animationType: isTabBar ? 'none' : animationType,
    });
  }
}

/**
 * 获取默认首页
 * @param {*} isAddSlash  是否加 "/“
 * @returns
 */
export function getDefaultHome({ isAddSlash = true } = {}) {
  const store = useCommonStore();
  const cateCode = store.categoryData.tabbarExpand?.cateCode;
  let homePage = 'pages/casino';

  if (checkPlatform() === 'TMA') {
    // homePage = 'pages/miniApp/bourse';
    homePage = 'pages/casino';
  } else {
    switch (cateCode) {
      case 'SPORTS':
        homePage = 'pages/sports';
        break;
      case 'BINGO':
        homePage = 'pages/bingo';
      case 'RECHARGE':
        homePage = 'pages/recharge';
      case 'CASINOVIVO':
        homePage = 'pages/casinoVivo';
        break;
      default:
        homePage = 'pages/casino';
        break;
    }
  }

  return isAddSlash ? `/${homePage}` : homePage;
}
/**
 * 提示toast
 * @param {*} msg
 */
export function toast(msg) {
  if (!msg) {
    return false;
  }
  uni.showToast({
    title: msg,
    icon: 'none',
    duration: 3000,
    position: 'bottom',
  });
  /* // msg = msg ? msg : '提示消息为空，请检查！'
  var toast = document.querySelector("#toast");
  if (!toast) {
    let div = document.createElement("div");
    div.id = "toast";
    div.className = "toast";
    div.innerHTML = `<span>${msg}</span>`;
    document.body.appendChild(div);
  } else {
    toast.querySelector("span").innerText = msg;
    toast.className = "toast";
  }
  setTimeout(function () {
    // 直接获取toast 为null
    document.querySelector("#toast").className = "hide";
  }, 3000); */
}

export function showLoading() {
  uni.showLoading({
    title: '',
    mask: true,
  });

  /* var loading = document.querySelector("#loading");
  if (loading) {
    loading.style.display = "block";
  } else {
    var div = document.createElement("div");
    div.id = "loading";
    div.innerHTML = `<div class="block">
                      <i class="img"></i>
                      <i class="gif"></i>
                    </div>`;
    document.body.appendChild(div);
  }
  // 阻止蒙层底部页面滚动
  const stopScroll = stopBodyScroll();
  stopScroll(true); */
}

export function hideLoading() {
  uni.hideLoading();
  /* var loadingDom = document.querySelector("#loading");
  if (loadingDom) {
    loadingDom.style.display = "none";
    const stopScroll = stopBodyScroll();
    stopScroll(false);
  } */
}

export function setToken(token) {
  setLocalData(`access_token`, token);
}

// 获取token
export function getToken() {
  return getLocalData('access_token') || '';
}

export function cleanToken() {
  delLocalData('access_token');
}

/**
 * 获取url上的参数
 * @param {*} name
 */
// 获取地址栏参数
// 获取地址栏参数
export function getQueryString(str) {
  //#ifdef APP-PLUS
  const routes = getCurrentPages(); // 获取当前打开过的页面路由数组
  const $page = routes[routes.length - 1]?.$page || routes[routes.length - 1]?.$vm?.$page;
  const curRoute = $page?.options;
  return curRoute[str] ? curRoute[str] : undefined;
  //#endif
  //#ifdef H5
  const reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) {
    return unescape(r[2]);
  }
  return undefined;
  //#endif
}

/**
 * 获取url路径上参数的值
 * @param {*} url
 * @param {*} str
 */
export function getParamsByStr(url, str) {
  let regex = new RegExp(`${str}=([^&]+)`);
  let match = url.match(regex);
  return match ? match[1] : null;
}

/**
 * 防抖
 * @param fun 需要加工的函数
 * @param delay 时间单位为：s，默认为350（一般200-500）
 * @param immediate 是否立即执行，默认为true
 * @returns
 */
export function debounce(fun, delay = 650, immediate = true) {
  var timer = null;
  return function () {
    var context = this;
    var args = arguments;

    if (timer) clearTimeout(timer);

    if (immediate) {
      // 立即执行
      fun.apply(context, args);
      immediate = false;
      setTimeout(function () {
        // TODO: 超过这个时间后再次重置immediate，保证再次点击的时候也可以执行
        immediate = true;
      }, delay);
    } else {
      timer = setTimeout(function () {
        // TODO: 这里不能在这里执行，否则用户连点之后，先立即执行一次，而后多会又会执行一次
        // fun.apply(context, args)
        // 这里逻辑处理：如果多个按钮执行同一方法逻辑的时候（同一按钮，下次点击的时候），确保点击任何一个按钮都可以先立即执行一次
        immediate = true;
      }, delay);
    }
  };
}

// 数组乱序
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * md5加密
 * @param {*} text
 */
export function md5(text) {
  return CryptoJS.MD5(text).toString();
}

/**
 * aes对称加密
 * @param {*} text
 */
export async function aesEncrypt(text, key) {
  key = CryptoJS.enc.Utf8.parse(key);
  text = CryptoJS.enc.Utf8.parse(text);
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    mode: CryptoJS.mode.ECB, // 这里使用ECB模式，请注意安全性考虑应使用更安全的CBC模式
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

/**
 * aes对称解密
 * @param {*} text
 */
export async function aesDecrypt(text, key) {
  key = CryptoJS.enc.Utf8.parse(key);
  const decrypted = CryptoJS.AES.decrypt(text, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

/** 动态加载加密库
 * rsa非对称加密
 * @param {*} text
 */
export async function rsaEncrypt(text) {
  try {
    /* const module = await import("jsencrypt");
    var encryptor = new module.JSEncrypt(); // 创建加密对象实例
    encryptor.setPublicKey(_publicKeyPEM); // 设置公钥
    return encryptor.encrypt(text); // 对内容进行加密 */

    var encryptor = new JSEncrypt(); // 创建加密对象实例
    encryptor.setPublicKey(_publicKeyPEM); // 设置公钥
    return encryptor.encrypt(text); // 对内容进行加密
  } catch (err) {
    throw err;
  }
}

/**
 * 阻止蒙层底部body页面滚动
 * @returns
 */
export function stopBodyScroll() {
  // #ifdef APP-PLUS
  /**
   * app 通过以下方法实现禁止页面滚动 https://ask.dcloud.net.cn/question/64257
   * 1、蒙版增加 @touchmove.stop.prevent="() => { }"  如果是popup，可搜索RechargeView.vue页面参考
   * 2、处理app滚动穿透：注意 page-meta 组件，一个页面只能存在一个  <page-meta :page-style="'overflow:' + (isShowBankList ? 'hidden' : 'visible')"></page-meta>
   */
  // 以下代码为兼容写法，防止报错
  return (isStop) => {
    if (isStop) {
    } else {
    }
  };
  // #endif

  // #ifdef H5
  let bodyEl = document.body,
    top = 0;
  return (isStop) => {
    if (isStop) {
      // top = window.scrollY
      top = document.documentElement.scrollTop || document.body.scrollTop || 0;
      // bodyEl.style.position = 'fixed'
      // bodyEl.style.top = -top + 'px'
      Object.assign(bodyEl.style, {
        position: 'fixed',
        top: -top + 'px',
        left: 0,
        right: 0,
        bottom: 0,
      });
    } else {
      // bodyEl.style.position = ''
      // bodyEl.style.top = ''

      Object.assign(bodyEl.style, {
        position: '',
        top: '',
        left: '',
        right: '',
        bottom: '',
      });

      window.scrollTo(0, top); // 回到原先的top
    }
  };
  // #endif
}

/**
 * 判断浏览器是在哪个端上,app内都返回false
 */
export function checkBrowser() {
  // #ifdef H5
  var userAgent = navigator.userAgent.toLowerCase();
  // 判断是否为手机浏览器
  var isMobile = /mobile|android|iphone|ipad|phone/i.test(userAgent);
  // 判断是否为微信浏览器
  var isWechat = /micromessenger/i.test(userAgent);
  // 判断是否为电脑浏览器
  var isDesktop = !isMobile && !isWechat;
  // 检查是否是 iOS 设备
  var isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  // 检查是否是 Android 设备
  var isAndroid = /android/i.test(userAgent);
  // 返回判断结果
  return {
    isMobile,
    isWechat,
    isDesktop,
    isIOS,
    isAndroid,
  };
  // #endif
  // #ifdef APP-PLUS
  return {
    isMobile: false,
    isWechat: false,
    isDesktop: false,
    isIOS: false,
    isAndroid: false,
  };
  // #endif
}

/**
 * 获取url路径上参数的值
 * @param {*} url
 * @param {*} str
 */
export function getUrlParams(url, str) {
  let regex = new RegExp(`${str}=([^&]+)`);
  let match = url.match(regex);
  return match ? match[1] : null;
}

/**
 * url添加代理字符串路径
 * @param {*} url
 * @returns
 */
export function addBasePath(url) {
  // return `poxyApi/${url}`;
  return `${config._apiPath}${url}`;
}

// TODO:需要改为有时间期限的data
/**
 * 读取本地的数据
 * @param {string} key key
 */
export function getLocalData(key, defaultValue) {
  // return window.localStorage && getData(key)
  //   ? getData(key) || defaultValue
  //   : getCookie(key) || defaultValue
  try {
    let temp = uni.getStorageSync(`${config._localEnv}${key}`);
    if ((temp === '' || temp === undefined) && defaultValue !== undefined) {
      temp = defaultValue;
    }
    return temp;
  } catch (e) {
    console.log('Error: uni.getStorageSync | ', e);
  }
}
/**
 * 设置本地数据
 * @param {string} key key
 */
export function setLocalData(key, value) {
  // window.localStorage ? setData(key, value) : setCookie(key, value)
  try {
    uni.setStorageSync(`${config._localEnv}${key}`, value);
  } catch (e) {
    console.log('Error: uni.setStorageSync | ', e);
  }
}
/**
 * 删除本地数据
 * @param {string} key 删除的key
 */
export function delLocalData(key) {
  // window.localStorage ? window.localStorage.removeItem(key) : delCookie(key)
  try {
    uni.removeStorageSync(`${config._localEnv}${key}`);
  } catch (e) {}
}

/**
 * 设置localStorage的key/value
 * @param {string} key
 * @param {string || number} value
 */
export function setData(key, value) {
  var value = encodeURIComponent(value);
  if (window.localStorage) {
    var storage = window.localStorage;
    try {
      storage.setItem(key, value);
    } catch (e) {
      storage.clear();
    }
  }
}
/**
 * 获取localStorage对应key的value值，否则返回null
 * @param {string} key 对应的key
 */
export function getData(key) {
  if (window.localStorage) {
    var storage = window.localStorage;
    if (storage.getItem(key)) {
      return decodeURIComponent(storage.getItem(key));
    } else {
      return null;
    }
  } else {
    return null;
  }
}
/**
 * 删除localStorage对应的key/value键值对
 * @param {string} key 对应的key
 */
export function delData(key) {
  if (window.localStorage) {
    var storage = window.localStorage;
    storage.removeItem(key);
  }
}
/**
 * 设置cookie值，默认7天
 * @param {string} key 对应key
 * @param {string || number} value 对应value
 * @param {number} days 有效天数（默认值是7），如果设置为0则立即失效
 * @param {string} domain 域名（默认值是dangdang.com）
 */
export function setCookie(key, value, days = 7, domain = '') {
  var exp = new Date();
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie =
    key + '=' + encodeURIComponent(value) + ';expires=' + exp.toGMTString() + ';path=/;domain=' + domain;
}
/**
 * 获取对应key的cookie值
 * @param {string} key 对应key
 */
export function getCookie(key) {
  var arr,
    reg = new RegExp('(^| )' + key + '=([^;]*)(;|$)');
  if ((arr = document.cookie.match(reg))) {
    return decodeURIComponent(arr[2]);
  }
  return null;
}
/**
 * 删除对应key的cookie值
 * @param {string} key 删除的key
 */
export function delCookie(key) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  document.cookie = key + '=;expires=' + exp.toGMTString() + '; path=/;domain=dangdang.com';
}

/**
 * 长时间-倒计时
 * @param {*} dateTime 日期的毫秒数
 * @param {*} callback
 */
var timer = null;
export function countdownLong(dateTime, callback) {
  let result = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  let time = dateTime;
  clearTimeout(timer);
  if (time > 0) {
    time -= 1000;
    timer = setTimeout(() => {
      result.days = Math.floor(time / (1000 * 60 * 60 * 24));
      result.hours = (Math.floor((time / (1000 * 60 * 60)) % 24) + '').padStart(2, 0);
      result.minutes = (Math.floor((time / (1000 * 60)) % 60) + '').padStart(2, 0);
      result.seconds = (Math.floor((time / 1000) % 60) + '').padStart(2, 0);
      callback && callback(result);

      countdownLong(time, callback);
    }, 1000);
  } else {
    callback && callback();
  }
}

// export function countdown(count, callback) {
//   let endTime = Date.now() + count * 1000;

//   function updateCount() {
//     let time = Date.now();
//     let diff = endTime - time;
//     if (diff > 0) {
//       diff = Math.floor(diff / 1000);
//       // let diffStr = diff >= 10 ? diff + '' : `0${diff}`
//       callback && callback(diff);

//       requestAnimationFrame(updateCount);
//     } else {
//       callback && callback();
//     }
//   }

//   requestAnimationFrame(updateCount);
// }
/**
 * 倒计时
 * @param {*} second 秒数
 * @param {*} callback
 * requestAnimationFrame 不兼容app
 */
export function countdown(second, callback) {
  let remainingTime = second;

  // Calculate and update the countdown at regular intervals (every second)
  const intervalId = setInterval(() => {
    remainingTime--;

    if (remainingTime >= 0) {
      callback && callback(remainingTime);
    } else {
      // Countdown has reached zero
      clearInterval(intervalId);
      callback && callback();
    }
  }, 1000);
}

/**
 * 日期格式化
 * @param {*} date
 * @param {*} format
 */
export function dateFormat(date, format) {
  if (!date) return false;
  if (typeof date == 'string' || typeof date == 'number') {
    date = new Date(date);
  }

  let year = date.getFullYear();
  let month = (date.getMonth() + 1 + '').padStart(2, '0');
  let day = (date.getDate() + '').padStart(2, '0');
  let hour = (date.getHours() + '').padStart(2, '0');
  let minute = (date.getMinutes() + '').padStart(2, '0');
  let second = (date.getSeconds() + '').padStart(2, '0');

  let result = '';
  switch (format) {
    case 'YYYY-MM-DD':
      result = `${year}-${month}-${day}`;
      break;
    case 'MM-DD-YYYY':
      result = `${month}-${day}-${year}`;
      break;
    case 'DD-MM-YYYY':
      result = `${day}-${month}-${year}`;
      break;
    case 'YYYY/MM/DD':
      result = `${year}/${month}/${day}`;
      break;
    case 'MM/DD/YYYY':
      result = `${month}/${day}/${year}`;
      break;
    case 'DD/MM/YYYY':
      result = `${day}/${month}/${year}`;
      break;

    // 带时分秒
    case 'YYYY-MM-DD HH:mm:ss':
      result = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      break;
    case 'MM-DD-YYYY HH:mm:ss':
      result = `${month}-${day}-${year} ${hour}:${minute}:${second}`;
      break;
    case 'DD-MM-YYYY HH:mm:ss':
      result = `${day}-${month}-${year} ${hour}:${minute}:${second}`;
      break;
    case 'YYYY/MM/DD HH:mm:ss':
      result = `${year}/${month}/${day} ${hour}:${minute}:${second}`;
      break;
    case 'MM/DD/YYYY HH:mm:ss':
      result = `${month}/${day}/${year} ${hour}:${minute}:${second}`;
      break;
    case 'DD/MM/YYYY HH:mm:ss':
      result = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
      break;

    case 'YYYYMMDD HH:mm:ss':
      result = `${year}${month}${day} ${hour}:${minute}:${second}`;
      break;

    case 'DD/MM HH:mm':
      result = `${day}/${month} ${hour}:${minute}`;
      break;
    case 'HH:mm:ss':
      result = `${hour}:${minute}:${second}`;
      break;
    default:
      result = `${year}${month}${day}`;
      break;
  }
  return result;
}

/**
 * 获取当前应用版本号
 */
export function getVersion() {
  return new Promise((resolve, reject) => {
    let version = '';
    //#ifdef H5
    version = `${packageConfig.versionName}_${packageConfig.versionCode}`;
    resolve(version);
    //#endif
    //#ifdef APP-PLUS
    plus.runtime.getProperty(plus.runtime.appid, (info) => {
      version = info.version;
      resolve(version);
    });
    //#endif
  });
}
// blob对象转图片文件对象
function blobToFile(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      const dataUrl = reader.result;
      const mime = dataUrl.split(',')[0].split(':')[1].split(';')[0];

      let binary = atob(dataUrl.split(',')[1]);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const file = new File([new Uint8Array(array)], blob.name, {
        type: mime,
      });
      resolve(file);
    };

    reader.onerror = function () {
      reject('No se puede convertir blob en archivo.');
    };

    reader.readAsDataURL(blob);
  });
}
// 图片压缩递归，小于1M跳出
const imageCompress = (file) => {
  return new Promise((resolve, reject) => {
    let { size, path } = file;
    //大于1M进行压缩，
    // #ifdef H5
    new Compressor(file, {
      quality: 0.6,
      success(res) {
        resolve(res);
      },
    });
    // resolve(file)
    return false;
    // #endif
    // #ifdef APP-PLUS
    if (size < 1024 * 1024) {
      resolve(file);
      return false;
    }
    uni.compressImage({
      src: path,
      width: '80%',
      height: '80%',
      success: (res) => {
        let newPath = res.tempFilePath;
        let newName = res.tempFilePath.split('/')[res.tempFilePath.split('/').length - 1];
        uni.getFileInfo({
          filePath: res.tempFilePath,
          success: async (info) => {
            let newFile = {
              ...file,
              size: info.size,
              path: newPath,
              name: newName,
              tempFilePath: res.tempFilePath,
            };
            resolve(await imageCompress(newFile));
          },
        });
      },
    });
    // #endif
  });
};

export const uploadImgUtil = ({
  count = 1,
  maxSize = 10,
  extension = ['.png', '.jpg', '.jpeg'],
  formDataFileName = '',
}) => {
  return new Promise((resolve, reject) => {
    const store = useCommonStore();

    uni.chooseImage({
      count,
      sizeType: ['original', 'compressed'],
      // 'album', 'camera'
      sourceType: ['album'],
      // extension,
      success: (chooseImageRes) => {
        let tempFiles = chooseImageRes.tempFiles;
        let allRight = true;
        tempFiles.forEach(async (element, index) => {
          if (!allRight) return;
          let fuenteFile = tempFiles[index];

          const fileSize = element.size / 1024 / 1024;
          const isLtMB = fileSize <= maxSize;
          if (!isLtMB) {
            allRight = false;
            return uni.showToast({
              icon: 'none',
              title: `El tamaño max es${maxSize}MB.`,
            });
          } else {
            let res = await imageCompress(tempFiles[index]);
            let header = {};
            header['content-language'] = store.checkSiteConfigRes?.lang || getLocalData('lang') || '';
            header['tenant-id'] = store.checkSiteConfigRes?.nationCode || getLocalData('nationCode') || '';
            header['content-sa'] = config._hostName; // 请求来源
            header['platform-id'] = store.checkSiteConfigRes?.siteCode || getLocalData('siteCode') || '';
            header['gray-version'] = '1.0.0'; // 灰度发布的参数

            let token = getToken();
            if (token) {
              header['Authorization'] = `Bearer ${token}`;
            }
            // 获取时区信息转换为 "GMT+/-X" 格式
            const offset = new Date().getTimezoneOffset();
            const offsetHours = Math.abs(Math.floor(offset / 60));
            const gmtString = `GMT${offset >= 0 ? '-' : '+'}${offsetHours}`;
            header['time-zone-id'] = gmtString;
            let filePath = '';
            // #ifdef H5
            filePath = res.name;
            // #endif
            // #ifdef APP-PLUS
            filePath = res.path;
            // #endif

            let ext = filePath.substring(filePath.lastIndexOf('.') + 1);
            let fileName = `${formDataFileName ? formDataFileName : 'default'}.${ext}`;

            // #ifdef APP-PLUS
            // #endif
            // #ifdef H5
            let blobToFileRes = await blobToFile(res);
            let newImgUrl = URL.createObjectURL(blobToFileRes);
            blobToFileRes.path = newImgUrl;
            res = blobToFileRes;

            // let formData = new FormData()
            // formData.append('file', res)
            // formData.append('fileName', `${formDataFileName ? formDataFileName : 'default'}.${res.type.split('/')[1]}`)
            // res = new File([res], fileName, {
            //   type: fuenteFile.type,
            //   lastModified: Date.now(),
            // });

            // #endif
            uni.uploadFile({
              url: `${config._apiPath}/system/upload`,
              filePath: res.path,
              name: 'file',
              header,
              formData: {
                fileName,
              },
              success: (uploadFileRes) => {
                resolve(uploadFileRes);
              },
              fail: (error) => {
                reject(error);
              },
            });
          }
        });
      },
    });
  });
};

export const copy = async ({ str, toastText }) => {
  //#ifdef APP-PLUS
  uni.setClipboardData({
    data: str,
    showToast: false,
    success: () => {
      if (toastText) {
        toast(toastText);
      }
    },
    fail: () => {
      toast($t('common.copyFail'));
    },
  });
  //#endif

  //#ifdef H5
  const textValue = document.createElement('textarea'); // 创建DOM元素
  document.body.appendChild(textValue); // 将创建的DOM插入到Body上
  textValue.value = str; // 将数据赋值给创建的DOM元素的Value上
  textValue.select(); // 通过表单元素的select()方法选中内容
  document.execCommand('copy'); // 执行浏览器复制命令
  if (toastText) {
    toast(toastText);
  }
  document.body.removeChild(textValue); // 移除DOM元素
  //#endif
};

export function handleExit() {
  const store = useCommonStore();
  const { switchLogin } = store;

  switchLogin(false);
  cleanToken();
  gotoPage({
    page: getDefaultHome(),
  });
}

/**
 * 使用正则表达式检查是否是10位数字
 * @param {*} str
 * @returns
 */
export function isNumericString(str) {
  let length = $t('common.phoneNumber');
  let regex = new RegExp(`^\\d{${length}}$`);
  return regex.test(str);
}

/**
 * https://www.oishare.com/tools/cpfdesc.html
 * @param {*} cpf
 * @returns
 */
function validarCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;
  // Valida 1o digito
  add = 0;
  for (i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
}

export function ganaSendEvent(key, val = {}) {
  const globalApp = getApp();
  const $ganaStat = globalApp.globalData.$ganaStat;

  let clientType = checkPlatform();
  // // #ifdef H5
  // if (getQueryString('siteType') === 'w2a' || getLocalData('siteType') === 'w2a') {
  //   clientType = 'w2a';
  // } else {
  //   clientType = 'h5';
  // }
  // // #endif
  // // #ifdef APP-PLUS
  // clientType = 'app';
  // // #endif
  try {
    // $ganaStat.sendEvent(`${clientType}-${props.pageType}-金刚区-点击-${item.cateName}`, {})
    $ganaStat.sendEvent(`${clientType}-${key}`, val);
  } catch (error) {}
}

export function phoneAreaCode() {
  return `+${$t('common.areaCode')}`;
}
/**
 * 获取远程配置图片路径
 */
export function getImgPath(fileName) {
  const store = useCommonStore();
  // img路径
  // https://files.igming.com/statics/asserts/rout.png
  // config._filePath = `files.gana100.com/statics/asserts/`;
  // config._filePath = `files.igming.com/statics/asserts/`;
  let path = `${config._filePath}/${store.checkSiteConfigRes?.siteCode || getLocalData('siteCode') || ''}/${fileName}`;
  // let path = `${config._filePath}/${fileName}`;
  return path;
}

/**
 * 获取socket地址
 * isLogin:登录状态
 */
export function getSocketPath(userName) {
  const store = useCommonStore();
  // `wss://${hostName}/proxy-api/config/api/front/push/anonymous/MX_2
  let path = `${config._socketPath}/anonymous/${store.checkSiteConfigRes?.siteCode || getLocalData('siteCode') || ''}`;
  if (userName) {
    path = `${config._socketPath}/auth/${store.checkSiteConfigRes?.siteCode || getLocalData('siteCode') || ''}/${userName}`;
  }
  return path;
}

/**
 * google firebase分析
 */
export function firebaseAnalytics() {
  // #ifdef APP-PLUS
  var analytics = uni.requireNativePlugin('sn-firebase-analytics');
  // getAppInstanceId - 获取应用实列ID
  analytics.getAppInstanceId((e) => {
    if (e.code == 0) {
      // e.id
    }
  });
  // getFirebaseInstanceId - 获取Firebase实列ID
  analytics.getFirebaseInstanceId((e) => {
    if (e.code == 0) {
      // e.id
    }
  });

  // setUserId - 设置用户ID
  analytics.setUserId('1234567890');
  // setUserProperty - 设置用户属性
  // analytics.setUserProperty("gender", "male");

  // setDefaultEventParameters - 设置默认事件参数
  analytics.setDefaultEventParameters({
    level_name: 'Caverns01',
    level_difficulty: 4,
  });

  // #endif
  // #ifdef H5
  // #endif
}

/**
 * google firebase 推送
 */
export function firebaseMessage() {
  // #ifdef APP-PLUS
  var message = uni.requireNativePlugin('sn-firebase-message');
  message.getToken((e) => {
    const data = e.data;
    console.log('firebaseMessage-------getToken', e, data);
    // try {
    //   COMMONAPI.firebaseMessageToken({
    //     token: data?.token,
    //   }).then((res) => {
    //     console.log('firebaseMessageToken', res);
    //   });
    // } catch (error) {

    // }
  });

  //首次启动获取启动参数
  var args = plus.runtime.arguments;
  console.log('plus.runtime.arguments------------', args);

  //再次启动获取启动参数，比如 点击通知栏信息
  plus.globalEvent.addEventListener('newintent', () => {
    var args = plus.runtime.arguments;
    if (args) {
      // 处理args参数，如直达到某新页面等
      console.log('onLaunch-newintent', args);
    }
  });

  // 监听fcm
  plus.globalEvent.addEventListener('fcmNewToken', (e) => {
    console.log('fcmNewToken', e);
  });
  plus.globalEvent.addEventListener('fcmMessageReceived', (e) => {
    console.log('fcmMessageReceived', e);
  });
  plus.globalEvent.addEventListener('fcmMessageReceivedData', (e) => {
    console.log('fcmMessageReceivedData', e);
  });
  plus.globalEvent.addEventListener('fcmMessageReceivedNotification', (e) => {
    console.log('fcmMessageReceivedNotification', e);
  });
  plus.globalEvent.addEventListener('fcmDeletedMessages', () => {
    console.log('fcmDeletedMessages');
  });

  // #endif
  // #ifdef H5
  // #endif
}

/**
 * @param {*} parsms
 * @returns
 * price: 要格式化的价格数值。
 * decimalsNum: 小数的位数
 * priceUnit 是否要用格式化 用MIL（千，K）和 MILLÓN（百万，M）
 * 40.000  ->  40 MIL
 * substring 截取小数点后的数字，toFixed会四舍五入
 * isIntegerShowZero 如果数值包含为".00"，就自动隐藏".00"，仅限巴西和墨西哥，且只限充值页面和充值活动页 详见任务宝箱需求(https://aajpmtck2cl.feishu.cn/wiki/WDolwIwC1imDgKkNAkDcC3PNnzT)
 */
export function formatPrice(price, decimalsNum = 2, { priceUnit = false, isIntegerShowZero = true } = {}) {
  if (price === undefined || price === null) {
    return;
  }
  if (typeof price === 'string') {
    price = +price;
  }

  // 根据国家代码来决定千位分隔符和小数点的形式
  let formatter = {
    thousands: ',',
    decimal: '.',
    decimalsNum: decimalsNum,
    kUnit: 'K',
    mbUnit: 'M',
  };
  let country = getLocalData('country');
  switch (country) {
    case 'MX':
      formatter.kUnit = 'MIL';
      formatter.mbUnit = 'MILLÓN';
      break;
    case 'BR':
      formatter.thousands = '.';
      formatter.decimal = ',';
      break;
    case 'CO':
      formatter.thousands = '.';
      formatter.decimal = ',';
      formatter.decimalsNum = 0; // 哥伦比亚不展示小数部分
      formatter.kUnit = 'MIL';
      formatter.mbUnit = 'MILLÓN';
      break;
    case 'CL':
      formatter.thousands = '.';
      formatter.decimal = '.';
      formatter.decimalsNum = 0; // 智力不展示小数部分
      formatter.kUnit = 'MIL';
      formatter.mbUnit = 'MILLÓN';
      break;
    case 'PE':
      formatter.thousands = ',';
      formatter.decimal = '.';
      formatter.kUnit = 'MIL';
      formatter.mbUnit = 'MILLÓN';
      break;
    case 'ID':
      formatter.thousands = '.';
      formatter.decimal = ',';
      formatter.decimalsNum = 0; // 印尼不展示小数部分
      break;
    default:
      break;
  }

  let resPrice = price;
  let unit = '';
  if (priceUnit) {
    if (resPrice >= 1000000) {
      resPrice = (resPrice / 1000000).toFixed(formatter.decimalsNum);
      unit = formatter.mbUnit;
    } else if (price >= 1000 && price < 1000000) {
      resPrice = (resPrice / 1000).toFixed(formatter.decimalsNum);
      unit = formatter.kUnit;
    }
  }

  let parts = parseFloat(resPrice).toFixed(formatter.decimalsNum).split('.');
  // 添加千位分隔符
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, formatter.thousands);
  resPrice = parts.join(formatter.decimal);
  if (priceUnit) {
    resPrice = `${resPrice}${unit}`;
  }

  return resPrice;
}

export const getTempFilePath = ({ canvasId, width, height }) => {
  return new Promise((resolve, reject) => {
    uni.canvasToTempFilePath({
      canvasId,
      width,
      height,
      success: (res) => {
        resolve(res.tempFilePath);
      },
      fail: (err) => {
        console.log(err);
        reject(err);
      },
    });
  });
};

export function getFriendlyCode(address) {
  return new Promise(async (resolve, reject) => {
    let data = await uniRequest({ url: 'https://toncenter.com/api/v2/packAddress', data: { address } });
    if (data.data.ok) {
      resolve(data.data.result);
    } else {
      reject('');
    }
  });
}

export function uniRequest({ url, method = 'GET', data }) {
  return new Promise((resolve, reject) => {
    uni.request({
      url,
      data,
      method,
      success: (res) => {
        resolve(res);
      },
      fail: (err) => {
        reject(err);
      },
    });
  });
}

export function formateLongStr(str, start = 8, end = 8) {
  if (str) {
    return `${str.substring(0, start)}...${str.substring(str.length - end, str.length)}`;
  }
  return '';
}

/**
 * 分享
 * @param {*} item
 * @param {*} inviteUrl
 * @param {*} extra
 * tipsVisible ：邀请好友页面 // 是否弹窗
 */
export function handleShare(item, inviteUrl, extra) {
  // 参考网站 https://www.oouro037.xyz/promotion/fission
  let pcShareUrl = '';
  let mShareUrl = '';

  switch (item.title) {
    case 'Facebook':
      //  pc: https://www.facebook.com/sharer.php?u=https://www.oouro037.xyz?inviteUrl=001059438
      //  M: fb://share/?link=https://www.oouro037.xyz?inviteUrl=001059438
      // pcShareUrl = `https://www.facebook.com/sharer.php?u=https://${config._hostName}${inviteUrl}`;
      pcShareUrl = `https://www.facebook.com/sharer.php?u=${inviteUrl}`;
      mShareUrl = `fb://share/?link=${inviteUrl}`;
      break;
    case 'WhatsApp':
      // pc: https://api.whatsapp.com/send/?text=https://www.oouro037.xyz?inviteUrl=001059438&type=phone_number&app_absent=0
      // M： whatsapp://send?text=https://www.oouro037.xyz?inviteUrl=001059438
      pcShareUrl = `https://api.whatsapp.com/send/?text=${inviteUrl}&type=phone_number&app_absent=0`;
      mShareUrl = `whatsapp://send?text=${inviteUrl}`;
      break;
    case 'Telegram':
      pcShareUrl = `https://t.me/share/url?url=${inviteUrl}`;
      mShareUrl = `https://t.me/share/url?url=${inviteUrl}`;
      // mShareUrl = `tg://msg_url?url=${inviteUrl}`;
      break;
    case 'Twitter':
      // pc: https://twitter.com/intent/tweet?text=https://www.oouro037.xyz?inviteUrl=001059438
      // M: twitter://post?message=https://www.oouro037.xyz?inviteUrl=001059438
      pcShareUrl = `https://twitter.com/intent/tweet?text=${inviteUrl}`;
      mShareUrl = `twitter://post?message=${inviteUrl}`;
      break;
  }

  let str = ``;
  if (extra) {
    if (extra.tipsVisible) {
      str = `邀请好友或代理-点击${item.title}-isModal: ${extra.tipsVisible}`;
    } else if (extra.pageType === 'signinDialog') {
      str = `首页弹窗分享-点击${item.title}`;
    }
    try {
      ganaSendEvent(str, {
        pcShareUrl,
        mShareUrl,
      });
    } catch (error) {}
  }

  const today = dateFormat(new Date());
  const isSigninToday = getLocalData(`isSigninToday`) || 0;
  if (!(isSigninToday && isSigninToday == today)) {
    setLocalData(`isSigninToday`, today);
  }
  console.log('---地址赋值-pcShareUrl：', pcShareUrl);
  // #ifdef H5
  // TODO:
  if (item.title == 'Telegram') {
    console.log('---tg直接open-pcShareUrl：', pcShareUrl, '--location.href:', window.location.href);
    // window.location.href = pcShareUrl;
    window.open(pcShareUrl, '_blank');
  } else {
    window.location.href = mShareUrl;
    // n秒后执行跳转
    const timer = setTimeout(async () => {
      window.open(pcShareUrl, '_blank');
      // window.open(pcShareUrl);
    }, 3000);
  }

  // 页面隐藏，那么代表已经调起了app，就清除下载的定时器
  const visibilitychange = function () {
    const dom = document;
    const tag = dom.hidden || dom.webkitHidden;
    tag && clearTimeout(timer);
  };

  document.addEventListener('visibilitychange', visibilitychange, false);
  document.addEventListener('webkitvisibilitychange', visibilitychange, false);
  window.addEventListener(
    'pagehide',
    function () {
      clearTimeout(timer);
    },
    false
  );
  // #endif
  // #ifdef APP-PLUS
  plus.runtime.openURL(pcShareUrl);
  // #endif
}

export function getText(str) {
  return str && str.replace('\n', '<br/>');
}

// 比较邀请代理账户是否变更
export function checkInviteAccountChanged(account) {
	let store = useCommonStore();
	let saveAccount = store.inviteAccount;
	if(saveAccount && account) {
		return account.inviteCount > saveAccount.inviteCount || account.rewardAmount > saveAccount.rewardAmount;
	}
	return false;
}
