<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  progress: {
    type: Boolean,
    default: false,
  },
})

// let percentage = 0

watch(
  () => props.progress,
  (val) => {
    if (val) {
      startTimer()
    }
  },
  { immediate: true }
)

let count = ref(0)

function startTimer() {
  const intervalId = setInterval(() => {
    // const randomIncrease = Math.floor(Math.random() * 10);
    // count.value += randomIncrease;
    count.value += 10;
    if (count.value >= 90) {
      clearInterval(intervalId);
    }
  }, 1000);
}



</script>
<template>
  <div class="skeleton-block">
    <image class="logo" src="@imgs/logo_2.png"></image>
    <image class="skeleton-logo" src="@imgs/common/common_loading.gif"></image>
    <up-line-progress :percentage="count" :activeColor="`var(--main-clr)`" v-show="progress"></up-line-progress>
  </div>
</template>
<style lang="scss" scoped>
.skeleton-block {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--page-bg) !important;
  z-index: 99999999;

  .logo {
    width: 236rpx;
    height: 58rpx;
    margin-bottom: 60rpx;
  }

  .skeleton-logo {
    height: 110rpx;
    width: 198rpx;
    // margin-top: -5vh;
  }
}

:deep(.u-line-progress) {
  width: 50%;
  border-radius: 0;
  flex: none;
  margin-top: 50rpx;

  .u-line-progress__text {
    color: black;
    margin-top: 2rpx;

    span {
      font-size: 30rpx;
      font-weight: bold;
    }
  }
}
</style>