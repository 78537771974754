import { pageEnum, tabBarMenu } from '@config';
import { useRouteStore } from '@stores/routeStore.js';
import { useCommonStore } from '@stores/common';
import {
  getToken,
  cleanToken,
  getLocalData,
  setLocalData,
  delLocalData,
  gotoLogin,
  getDefaultHome,
  checkPlatform,
} from '@util';

function queryURLparamsRegEs6(url) {
  let obj = {};
  let reg = /([^?=&]+)=([^?=&]+)/g;
  url?.replace(reg, (...arg) => {
    obj[arg[1]] = arg[2];
  });
  return obj;
}
export default async function () {
  const routeStore = useRouteStore();
  const { setNeedAutoBack } = routeStore;
  const store = useCommonStore();
  const { setIsShowRechargeDia } = store;

  let list = ['navigateTo', 'redirectTo', 'reLaunch', 'switchTab'];
  list.forEach((item) => {
    //用遍历的方式分别为,uni.navigateTo,uni.redirectTo,uni.reLaunch,uni.switchTab这4个路由方法添加拦截器
    uni.addInterceptor(item, {
      invoke(e) {
        if (!e.url) {
          return e;
        }
        // 调用前拦截
        const queryURLparams = queryURLparamsRegEs6(e.url);
        let pages = getCurrentPages();
        console.log('-|-pages: ', pages);
        let platform = checkPlatform();
        let $page = pages[pages.length - 1];
        let $pageFullPath = $page && $page.$page && $page.$page.fullPath; // 当前页面路径
        if (platform == 'TMA') {
          $pageFullPath = $page && $page.$page && $page.$page.path; // 当前页面路径
        }
        // console.error('addInterceptor', e, e.url, $pageFullPath, $page)
        const url = e.url.split('?')[0]; // 上个页面或下个页面
        console.log('-|-url: ', url);
        /*  // 需要登录权限的页面
        const needLoginAuthList = [
          pageEnum['recharge'],
        ];
        if (needLoginAuthList.include(url)) {
          gotoLogin();
        } */

        // 跳转以下页面需要自动返回from页面
        const needAutoBackList = [
          pageEnum['mobileModify'], // 修改手机号页面
          pageEnum['mobileVerify'], // 验证手机号页面
          pageEnum['recharge'], //提现页面
        ];

        let isNeedAutoBack = needAutoBackList.includes(url);
        if (isNeedAutoBack) {
          console.log('-|-setNeedAutoBack1：', $pageFullPath);
          setNeedAutoBack(`${$pageFullPath}`); // 带参数路由
        }
        // if ([url].includes('/pages/playGame')) {
        //   // 跳转到游戏页面，返回需调usercenter接口
        //   setNeedAutoBack(`${$pageFullPath}`); // 带参数路由
        //   // setLocalData('routerType', e.url)
        // }

        // [url].includes('/pages/playGame') 会导致游戏页面刷新无法返回上一页
        // 以下处理首页跳转游戏，在游戏内刷新，然后返回
        let needBackHome = ['/pages/resetPwd'].includes($pageFullPath); // 上个页面是否需要返回首页
        if (needBackHome) {
          // 需要返回首页
          console.log('-|-setNeedAutoBack2：', getDefaultHome());
          setNeedAutoBack(getDefaultHome()); // 设置需要返回的页面为默认首页，否则跳转登录再次点击login按钮会返回当前页面
        } else if ($pageFullPath?.indexOf('/pages/playGame') < 0) {
          // TODO:有问题吗？
          // 跳转到游戏页面，返回需调usercenter接口
          console.log('-|-setNeedAutoBack3：', $pageFullPath);
          setNeedAutoBack(`${$pageFullPath}`); // 带参数路由
        }

        if (
          ['/pages/recharge'].includes($pageFullPath) &&
          !getLocalData(`${store.baseUserInfo.userName}_clickRechageBtn`)
        ) {
          // 充值页面添加弹窗确认
          setIsShowRechargeDia(true, url);
          return false;
        }

        if (['/pages/home'].includes(url)) {
          // 跳转到404,处理不允许访问重复跳转bug
          return false;
        }

        if ($page && ['/pages/login'].includes($page.route)) {
          // 跳转到登录页,处理不允许访问重复跳转bug【判断上一个页面，而非当前页，所以用$page】
          return false;
        }
        return e;
      },
      fail(err) {
        // 失败回调拦截
        // console.error('addInterceptor---------fail', err)
      },
    });
  });
}
