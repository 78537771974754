import en from '../../locale/en.json';
import es from '../../locale/es.json';
import co from '../../locale/co.json';
import zh from '../../locale/zh.json';
import br from '../../locale/br.json';
import ng from '../../locale/ng.json';
import cl from '../../locale/cl.json';
import pe from '../../locale/pe.json';
import id from '../../locale/id.json';
import ec from '../../locale/ec.json';

const messages = {
  es,
  co,
  en,
  zh,
  br,
  ng,
  cl,
  pe,
  id,
  ec,
};

let language = uni.getLocale() || 'es'; // 默认语言
export const $t = (str) => {
  Object.keys(messages).forEach(function (key) {
    if (language.indexOf(key) === 0) {
      language = key;
    }
  });
  return messages[language][str] ? messages[language][str] : str;
};
